export const headers = [
  'Дата',
  'Номер заказа',
  'Курьер',
  'Курьер 2',
  'Статус',
  'Тип товара',
  'Откуда',
  'Отправитель',
  'Куда',
  'Получатель',
  'Сумма',
  'Выкуп',
  'Кто оплачивает',
  'SenderPays',
];
export const headersForCompletedOrders = [
  'Дата создания',
  'Дата доставки',
  'Номер заказа',
  'Курьер',
  'Курьер 2',
  'Статус',
  'Тип товара',
  'Откуда',
  'Отправитель',
  'Куда',
  'Получатель',
  'Сумма',
  'Выкуп',
  'Кто оплачивает',
  'SenderPays',
];

export const headersForTransactions = [
  'Дата',
  'Курьер',
  'Отправитель',
  'Получатель',
  'Баланс до',
  'Сумма',
  'Баланс после',
  'Комментарии',
];
export const headersFotTransactions2 = [
  'Дата',
  'Курьер',
  'Отправитель',
  'Получатель',
  'Тип расхода',
  'Баланс до',
  'Сумма',
  'Баланс после',
  'Комментарии',
];

export const statusClassMap = {
  status_new: 'new-order',
  status_confirmed: 'confirmed-order',
  status_arrived_sender: 'arrived-sender-order',
  status_on_courier: 'on-courier-order',
  status_at_sorting_center: 'at-sorting-order',
  status_at_sorting_center2: 'at-sorting-order',
  status_on_way_cc: 'on-way',
  status_on_courier2: 'on-way',
  status_arrived_receiver: 'arrived-receiver-order',
  status_delivered: 'delivered-order',
  status_rejected: 'rejected-order',
  status_rejected_gived: 'delivered-order',
  status_cancelled: 'cancelled-order',
};

export const statusClassMapForReturned = {
  status_rejected: 'confirmed-order',
  status_rejected_on_way: 'on-way',
  status_rejected_sort_center: 'at-sorting-order',
  status_rejected_courier: 'on-courier-order',
  status_rejected_gived: 'delivered-order',
}

export const returnedOrderStatusLabels = {
  status_rejected: 'Возврат оформлен',
  status_rejected_on_way: 'Возврат в пути',
  status_rejected_sort_center: 'Возврат в сорт центре',
  status_rejected_courier: 'Возврат у курьера',
  status_rejected_gived: 'Возврат завершен',
};

export const returnedOrderStatus = [
  { name: 'Возврат оформлен', value: 'status_rejected' },
  { name: 'Возврат в пути', value: 'status_rejected_on_way' },
  { name: 'Возврат в сорт центре', value: 'status_rejected_sort_center' },
  { name: 'Возврат у курьера', value: 'status_rejected_courier' },
  { name: 'Возврат завершен', value: 'status_rejected_gived' },
];

export const returnedOrderAlternativeStatusLabels = {
  'Возврат оформлен': 'status_rejected',
  'Возврат в пути': 'status_rejected_on_way',
  'Возврат в сорт центре': 'status_rejected_sort_center',
  'Возврат у курьера': 'status_rejected_courier',
  'Возврат завершен': 'status_rejected_gived',
};

export const fieldsToSearchOrder = [
  'id',
  'pid',
  'receiver',
  'sender',
  'senderName',
  'courierOne',
  'courierTwo',
];

