import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { query, where, orderBy, startAfter, limit, getDocs } from "firebase/firestore";
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

const ExportToExcel = ({
  firestoreRef,
  selectedFilters,
  headers,
  selectedDate,
  selectedDate2,
  fileName,
  search,
  dateField,
  dataFormatter,
  pageSize = 100,
  isClientExport = false,
}) => {
  const [loading, setLoading] = useState(false);
  const includingIndexText = 'The query requires an index. You can create it here:'
  const indexErrorText = 'Ошибка экспорта в Excel: Необходим индекс для выполнения запроса'

  const exportToExcel = async () => {
    setLoading(true)
    try {
      let q = query(firestoreRef);
      if (dateField) {
        q = query(q, orderBy(dateField, 'desc'));
      }

      Object.keys(selectedFilters).forEach((field) => {
        const value = selectedFilters[field];

        if (value !== 'Все' && value !== '') {
          q = query(q, where(field, '==', value));
        }
      });
      if (isClientExport) {
        if (selectedFilters['address.city'] && selectedFilters['address.city'] !== 'Все') {
          q = query(q, where('address.city', '==', selectedFilters['address.city']));
        }
        const appValue = selectedFilters['password'];
        if (appValue === 'Установлено') {
          q = query(q, where('password', '!=', ''));
        } else if (appValue === 'Не установлено') {
          q = query(q, where('password', '==', ''));
        }
      }
      if (search) {
        q = query(q, where('forSearch', 'array-contains', search.toLowerCase()));
      }
      if (dateField && selectedDate && selectedDate2) {
        const startOfDay = new Date(selectedDate);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(selectedDate2);
        endOfDay.setHours(23, 59, 59, 999);
        q = query(q, where(dateField, '>=', startOfDay), where(dateField, '<=', endOfDay));
      }

      const allOrders = [];
      let lastDoc = null;
      while (true) {
        if (lastDoc) {
          q = query(q, startAfter(lastDoc), limit(pageSize));
        } else {
          q = query(q, limit(pageSize));
        }

        const querySnapshot = await getDocs(q);
        const newOrders = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        allOrders.push(...newOrders);

        if (querySnapshot.docs.length < pageSize) {
          break;
        }

        lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      }
      const snapshot = allOrders.map(dataFormatter);
      const finalData = [headers, ...snapshot];
      const ws = XLSX.utils.aoa_to_sheet(finalData);
      ws['!cols'] = Array(finalData[0].length).fill({ wch: 22 });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, fileName);

      XLSX.writeFile(wb, `${fileName}.xlsx`);
      setLoading(false)

    } catch (error) {
      setLoading(false)
      console.error("Error exporting to Excel:", error)

      if (error.message.includes(includingIndexText)) {
        toast.error(indexErrorText, {
          autoClose: 5000,
          hideProgressBar: false,
        });
      } else {
        toast.error('Ошибка экспорта в Excel: Произошла неизвестная ошибка.', {
          autoClose: 5000,
          hideProgressBar: false,
        });
      }
    }
  };

  return (
    <LoadingButton
      variant={loading ? 'contained' : 'black-contained'}
      loading={loading}
      loadingIndicator="Экспортируется…"
      onClick={exportToExcel}
    >
      Экспорт в Excel
    </LoadingButton>
  );
};

export default ExportToExcel;
