import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import { dateFormatter } from "../../../../../utils/dateFormatter";
import { getStatusClass, getStatusClassForReturned } from "./getStatusClass";
import { orderStatus, packageTypeLabels, paymentMethods, statusLabels } from "../../../../../utils";
import { returnedOrderStatus } from "../constants";
import AutoLink from "../../../../../components/AutoLink";

const ActionIconButton = ({ title, icon, onClick }) => (
  <Tooltip title={title} arrow>
    <IconButton color='primary' aria-label='edit' onClick={onClick}>
      {React.cloneElement(icon, { style: { color: '#000b1f' } })}
    </IconButton>
  </Tooltip>
);

const getStatusLabel = (status, customLabels) => {
  return customLabels ? customLabels[status] : statusLabels[status];
};
const getPaymentLabel = (status) => {
  return paymentMethods[status];
};

export const orderColumns = ({ actionsConfig, path, editable, customLabels, orderType }) => {
  const actionsColumn = {
    field: 'actions',
    sortable: false,
    width: !actionsConfig ? 0 : 170,
    menubar: false,
    renderCell: (params) => (
      <div>
        {!actionsConfig ? null : (
          <>
            <Tooltip title='Детали' arrow>
              <Link to={`${path}/${params.id}`} target='_blank'>
                <IconButton color='primary' aria-label='edit'>
                  <RemoveRedEyeRoundedIcon style={{ color: '#000b1f' }} />
                </IconButton>
              </Link>
            </Tooltip>
            {actionsConfig.map(
              (action, index) =>
                action.visible !== false && (
                  <ActionIconButton
                    key={index}
                    title={action.title}
                    icon={action.icon}
                    onClick={() => action.handler(params)}
                  />
                )
            )}
          </>
        )}
      </div>
    ),
    renderHeader: () => <strong>{!actionsConfig ? '' : 'Действия'}</strong>,
  };
  return [
    {
      field: 'details',
      headerName: '',
      width: 1,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title='Детали' arrow >
          <Link to={`${path}/${params.id}`} target='_blank'>
            <IconButton color='primary' aria-label='edit'>
              <RemoveRedEyeRoundedIcon style={{ color: '#000b1f' }} />
            </IconButton>
          </Link>
        </Tooltip>
      )
    },
    {
      width: 140,
      field: 'dateCreated',
      sortable: true,
      renderCell: (params) => {
        return <div>{dateFormatter(params.row.dateCreated.seconds)}</div>;
      },
      renderHeader: () => <strong>Дата создания</strong>,
      disableColumnMenu: false,
    },
    ...(orderType === 'completed'
      ? [{
        width: 140,
        field: 'dateDelivered',
        sortable: true,
        renderCell: (params) => {
          return <div>{dateFormatter(params?.row?.dateDelivered?.seconds)}</div>;
        },
        renderHeader: () => <strong>Дата доставки</strong>,
        disableColumnMenu: false,
      }]
      : []),
    {
      width: 200,
      field: 'pid',
      sortable: false,
      renderHeader: () => <strong>Номер заказа</strong>,
      renderCell: (params) => {
        return <div>{!params.row.pid ? 'Отсутствует' : params.row.pid}</div>;
      },
    },
    {
      width: 120,
      field: 'courierOne',
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {!params.row.courierOne ? 'Отсутствует' : params.row.courierOne}
          </div>
        );
      },
      renderHeader: () => <strong>Курьер</strong>,
    },
    {
      width: 120,
      field: 'courierTwo',
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {!params.row.courierTwo ? 'Отсутствует' : params.row.courierTwo}
          </div>
        );
      },
      renderHeader: () => <strong>Курьер 2</strong>,
    },
    {
      width: 180,
      field: 'status',
      sortable: false,
      headerAlign: 'center',
      editable: editable ?? false,
      type: 'singleSelect',
      valueOptions: !customLabels ? orderStatus.map((status) => status.name) : returnedOrderStatus.map((status) => status.name),
      renderCell: (params) => {
        const statusClassName = !customLabels ? getStatusClass(params.row.status) : getStatusClassForReturned(params.row.status);
        return (
          <strong className={statusClassName}>
            {getStatusLabel(params.row.status, customLabels)}
          </strong>
        );
      },
      renderHeader: () => <strong>Статус</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 100,
      field: 'packageType',
      sortable: false,
      renderCell: (params) => {
        return <div>{packageTypeLabels[params.row.packageType]}</div>;
      },
      renderHeader: () => <strong>Тип товара</strong>,
    },
    {
      width: 200,
      field: 'addressFrom',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values client'>
            <span> {params?.row?.addressFrom?.cityName ?? ''}</span>
            <span> {params?.row?.addressFrom?.address ?? ''}</span>
          </div>
        );
      },
      renderHeader: () => <strong>Откуда</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 154,
      field: 'sender',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values client'>
            <span>{params.row.senderName}</span>
            <span>{params.row.sender}</span>
          </div>
        );
      },
      renderHeader: () => <strong>Отправитель</strong>,
    },
    {
      width: 200,
      field: 'addressTo',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values client'>
            <span> {params?.row?.addressTo?.cityName ?? ''}</span>
            <span> {params?.row?.addressTo?.address ?? ''}</span>
          </div>
        );
      },
      renderHeader: () => <strong>Куда</strong>,
    },
    {
      width: 154,
      field: 'receiver',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values client'>
            <span> {params.row.receiverName}</span>
            <span> {params.row.receiver}</span>
          </div>
        );
      },
      renderHeader: () => <strong>Получатель</strong>,
    },
    {
      width: 80,
      field: 'cost',
      sortable: false,
      renderCell: (params) => {
        return <div>{params.row.cost}с</div>;
      },
      renderHeader: () => <strong>Сумма</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 100,
      field: 'redemption',
      sortable: false,
      renderCell: (params) => {
        return <div>{params.row.redemption}с</div>;
      },
      renderHeader: () => <strong>Выкуп</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 130,
      field: 'whoPays',
      sortable: false,
      renderCell: (params) => {
        let whoPaysText;
        switch (params.row.whoPays) {
          case 1:
            whoPaysText = 'Отправитель';
            break;
          case 2:
            whoPaysText = 'Получатель';
            break;
          default:
            whoPaysText = 'Совместно';
        }
        return <div>{whoPaysText}</div>;
      },
      renderHeader: () => <strong>Кто оплачивает</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 130,
      field: 'paymentMethod',
      sortable: false,
      renderCell: (params) => (
        <span>
          {getPaymentLabel(params.row.paymentMethod)}
        </span>
      ),
      renderHeader: () => <strong>Метод оплаты</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 130,
      field: 'senderPays',
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.senderPays === null ? 0.0 : params?.row?.senderPays}
          </div>
        );
      },
      renderHeader: () => <strong>SenderPays</strong>,
      cellClassName: 'centered-cell',
    },
    actionsColumn
  ]
};

export const orderColumnsCity = ({ actionsConfig, path, editable, customLabels }) => {
  const actionsColumn = {
    field: 'actions',
    sortable: false,
    width: !actionsConfig ? 0 : 170,
    menubar: false,
    renderCell: (params) => (
      <div>
        {!actionsConfig ? null : (
          <>
            <Tooltip title='Детали' arrow>
              <Link to={`${path}/${params.id}`} target='_blank'>
                <IconButton color='primary' aria-label='edit'>
                  <RemoveRedEyeRoundedIcon style={{ color: '#000b1f' }} />
                </IconButton>
              </Link>
            </Tooltip>
            {actionsConfig.map(
              (action, index) =>
                action.visible !== false && (
                  <ActionIconButton
                    key={index}
                    title={action.title}
                    icon={action.icon}
                    onClick={() => action.handler(params)}
                  />
                )
            )}
          </>
        )}
      </div>
    ),
    renderHeader: () => <strong>{!actionsConfig ? '' : 'Действия'}</strong>,
  };
  return [
    {
      field: 'details',
      headerName: '',
      width: 1,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title='Детали' arrow >
          <Link to={`${path}/${params.id}`} target='_blank'>
            <IconButton color='primary' aria-label='edit'>
              <RemoveRedEyeRoundedIcon style={{ color: '#000b1f' }} />
            </IconButton>
          </Link>
        </Tooltip>
      )
    },
    {
      width: 140,
      field: 'dateCreated',
      sortable: true,
      renderCell: (params) => {
        return <div>{dateFormatter(params.row.dateCreated.seconds)}</div>;
      },
      renderHeader: () => <strong>Дата создания</strong>,
      disableColumnMenu: false,
    },
    {
      width: 200,
      field: 'pid',
      sortable: false,
      renderHeader: () => <strong>Номер заказа</strong>,
      renderCell: (params) => {
        return <div>{!params.row.pid ? 'Отсутствует' : params.row.pid}</div>;
      },
    },
    {
      width: 120,
      field: 'courierOne',
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {!params.row.courierOne ? 'Отсутствует' : params.row.courierOne}
          </div>
        );
      },
      renderHeader: () => <strong>Курьер</strong>,
    },
    {
      width: 120,
      field: 'courierTwo',
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {!params.row.courierTwo ? 'Отсутствует' : params.row.courierTwo}
          </div>
        );
      },
      renderHeader: () => <strong>Курьер 2</strong>,
    },
    {
      width: 180,
      field: 'status',
      sortable: false,
      headerAlign: 'center',
      editable: editable ?? false,
      type: 'singleSelect',
      valueOptions: !customLabels ? orderStatus.map((status) => status.name) : returnedOrderStatus.map((status) => status.name),
      renderCell: (params) => {
        const statusClassName = !customLabels ? getStatusClass(params.row.status) : getStatusClassForReturned(params.row.status);
        return (
          <strong className={statusClassName}>
            {getStatusLabel(params.row.status, customLabels)}
          </strong>
        );
      },
      renderHeader: () => <strong>Статус</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 100,
      field: 'packageType',
      sortable: false,
      renderCell: (params) => {
        return <div>{packageTypeLabels[params.row.packageType]}</div>;
      },
      renderHeader: () => <strong>Тип товара</strong>,
    },
    {
      width: 250,
      field: 'addressFrom',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values client'>
            <span> {params.row.addressFrom.districtName} / {params.row.addressFrom.address}</span>
            <span><AutoLink text={params.row.addressFrom.link2gis} /></span>
          </div>
        );
      },
      renderHeader: () => <strong>Откуда</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 154,
      field: 'sender',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values client'>
            <span>{params.row.senderName}</span>
            <span>{params.row.sender}</span>
          </div>
        );
      },
      renderHeader: () => <strong>Отправитель</strong>,
    },
    {
      width: 200,
      field: 'addressTo',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values client'>
            <span> {params.row.addressTo.districtName} / {params.row.addressTo.address}</span>
            <span>{params.row.addressTo.link2gis}</span>
          </div>
        );
      },
      renderHeader: () => <strong>Куда</strong>,
    },
    {
      width: 154,
      field: 'receiver',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values client'>
            <span> {params.row.receiverName}</span>
            <span> {params.row.receiver}</span>
          </div>
        );
      },
      renderHeader: () => <strong>Получатель</strong>,
    },
    {
      width: 80,
      field: 'cost',
      sortable: false,
      renderCell: (params) => {
        return <div>{params.row.cost}с</div>;
      },
      renderHeader: () => <strong>Сумма</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 100,
      field: 'redemption',
      sortable: false,
      renderCell: (params) => {
        return <div>{params.row.redemption}с</div>;
      },
      renderHeader: () => <strong>Выкуп</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 130,
      field: 'whoPays',
      sortable: false,
      renderCell: (params) => {
        let whoPaysText;
        switch (params.row.whoPays) {
          case 1:
            whoPaysText = 'Отправитель';
            break;
          case 2:
            whoPaysText = 'Получатель';
            break;
          default:
            whoPaysText = 'Совместно';
        }
        return <div>{whoPaysText}</div>;
      },
      renderHeader: () => <strong>Кто оплачивает</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 130,
      field: 'senderPays',
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.senderPays === null ? 0.0 : params?.row?.senderPays}
          </div>
        );
      },
      renderHeader: () => <strong>SenderPays</strong>,
      cellClassName: 'centered-cell',
    },
    actionsColumn
  ]
};