import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { getDocs, query, where, getCountFromServer, orderBy } from 'firebase/firestore';
import { Button, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Tooltip } from '@mui/material';
import { orderColumns } from '../Orders/helpers/orderColumns';
import { handleCopyOrder } from '../Orders/helpers/handleCopyOrder';
import { returnedOrderStatusLabels } from '../Orders/constants';
import { citiesRef, completedOrdersRef, orderRef, returnedOrderRef } from '../../../../utils/collectionRefs';

import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import { Loader } from '../../../../components/Loader/Loader';
import OrderGrid from '../../../../components/OrderGrid ';

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
  LineChart,
  Line,
} from 'recharts';

import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import { DatePicker } from '@mui/x-date-pickers';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import InfoIcon from '@mui/icons-material/Info';
import './scss/Search.scss'

const Search = ({ pageTitle }) => {
  const [orders, setOrders] = useState([]);
  const [ordersDone, setOrdersDone] = useState([]);
  const [ordersReturned, setOrdersReturned] = useState([]);

  const [cities, setCities] = useState([])
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false)

  const [counts, setCounts] = useState({ active: 0, completed: 0, returned: 0 })
  const { handleSubmit, control, reset, setValue, watch } = useForm({ mode: 'onChange' });

  const green = '#15B79F';
  const yellow = '#FB9C0C';
  const lavanda = '#635BFF';

  const navigate = useNavigate()
  const location = useLocation()

  const phoneNumber = watch('phoneNumber');
  const searchType = watch('searchType');


  const onSubmit = async (data) => {
    setLoading(true);

    const { phoneNumber, searchType, dateFrom, dateTo, cityTo, cityFrom } = data;
    const filters = []
    const completedOrderFilters = []
    if (dateFrom) {
      filters.push(where('dateCreated', '>=', dateFrom.toDate()));
      completedOrderFilters.push(where('dateDelivered', '>=', dateFrom.toDate()));
    }
    if (dateTo) {
      filters.push(where('dateCreated', '<=', dateTo.toDate()));
      completedOrderFilters.push(where('dateDelivered', '<=', dateTo.toDate()));
    }
    if (cityFrom) {
      filters.push(where('cityFrom', '==', cityFrom));
      completedOrderFilters.push(where('cityFrom', '==', cityFrom));
    }
    if (cityTo) {
      filters.push(where('cityTo', '==', cityTo));
      completedOrderFilters.push(where('cityTo', '==', cityTo));
    }
    if (phoneNumber) {
      if (searchType === 'couriersForFilter') {
        filters.push(where(searchType, 'array-contains', phoneNumber));
        completedOrderFilters.push(where(searchType, 'array-contains', phoneNumber));
      } else {
        filters.push(where(searchType, '==', phoneNumber));
        completedOrderFilters.push(where(searchType, '==', phoneNumber));
      }
    }
    filters.push(orderBy('dateCreated', 'desc'))

    const queries = [
      query(orderRef, ...filters),
      query(completedOrdersRef, ...completedOrderFilters),
      query(returnedOrderRef, ...filters),
    ];

    try {
      const [activeCount, completedCount, retrunedCount] = await Promise.all(
        queries.map(q => getCountFromServer(q))
      );
      setCounts({
        active: activeCount.data().count,
        completed: completedCount.data().count,
        returned: retrunedCount.data().count
      });
      const [active, completed, returned] = await Promise.all(
        queries.map(q => getDocs(q))
      );

      const ordersData = active.docs.map(doc => ({ ...doc?.data(), id: doc.id }));
      const ordersDoneData = completed.docs.map(doc => ({ ...doc?.data(), id: doc.id }));
      const ordersReturnedData = returned.docs.map(doc => ({ ...doc?.data(), id: doc.id }));

      setOrders(ordersData);
      setOrdersDone(ordersDoneData);
      setOrdersReturned(ordersReturnedData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const courierId = params.get('courierId');
    if (courierId) {
      setValue('phoneNumber', courierId);
      setValue('searchType', 'couriersForFilter');
    }
  }, [location.search, setValue]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const courierId = params.get('courierId');
    if (courierId && searchType === 'couriersForFilter') {
      handleSubmit(onSubmit)();
    }
  }, [searchType, phoneNumber, handleSubmit, location.search]);

  useEffect(() => {
    fetchData(citiesRef, 'name', 'asc', setCities);
  }, []);

  const fetchData = async (collectionRef, sortField, sortOrder, setData) => {
    try {
      const querySnapshot = await getDocs(
        query(collectionRef, orderBy(sortField, sortOrder))
      );
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      }));
      setData(data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  const handleTabChange = useCallback((event, newIndex) => {
    setTabIndex(newIndex);
  }, []);


  const actionsConfig = [
    {
      title: 'Копировать',
      icon: <FileCopyRoundedIcon style={{ color: '#000b1f' }} />,
      handler: handleCopyOrder,
    },
    {
      title: 'Редактировать',
      icon: <NoteAltRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/order/update/${params.id}`),
    },
    {
      title: 'История',
      icon: <AccessTimeFilledRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/order/history/orders/${params.id}`)
    },
  ];

  const columns = orderColumns({
    actionsConfig: actionsConfig,
    path: tabIndex === 0 ? '/order/details' : tabIndex === 1 ? '/order/completed/details' : '/order/returned/details',
    customLabels: tabIndex === 2 ? returnedOrderStatusLabels : undefined,
    orderType: tabIndex === 1 ? 'completed' : '',
  })

  const handleReset = () => {
    reset({
      phoneNumber: '+996',
      cityFrom: '',
      cityTo: '',
      dateFrom: null,
      dateTo: null,
      searchType: 'sender',
    });
    setOrders([]);
    setOrdersDone([]);
    setOrdersReturned([]);
    setCounts({ active: 0, completed: 0, returned: 0 });
  };

  const dateFormatterWithoutYear = useCallback((timestamp) => {
    if (!timestamp || !timestamp.toDate) {
      return ''; // или какое-то другое значение по умолчанию
    }

    const date = timestamp.toDate();
    if (!date) {
      return ''; // или какое-то другое значение по умолчанию
    }

    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    return `${month} ${day}`;
  }, []);

  const activeChartData = useMemo(() => {
    const combinedData = {};
    orders?.forEach(order => {
      const date = dateFormatterWithoutYear(order.dateCreated);
      if (!combinedData[date]) combinedData[date] = { date };
      combinedData[date].Активные = (combinedData[date].Активные || 0) + 1;
    });
    return Object.values(combinedData);
  }, [orders, dateFormatterWithoutYear]);

  const doneChartData = useMemo(() => {
    const combinedData = {};
    ordersDone?.forEach(order => {
      const date = dateFormatterWithoutYear(order.dateDelivered);
      if (!combinedData[date]) combinedData[date] = { date };
      combinedData[date].Доставленные = (combinedData[date].Доставленные || 0) + 1;
    });
    return Object.values(combinedData);
  }, [ordersDone, dateFormatterWithoutYear]);

  const returnedChartData = useMemo(() => {
    const combinedData = {};
    ordersReturned?.forEach(order => {
      const date = dateFormatterWithoutYear(order.dateCreated);
      if (!combinedData[date]) combinedData[date] = { date };
      combinedData[date].Возвраты = (combinedData[date].Возвраты || 0) + 1;
    });
    return Object.values(combinedData);
  }, [ordersReturned, dateFormatterWithoutYear]);

  const getChartData = () => {
    switch (tabIndex) {
      case 0:
        return activeChartData;
      case 1:
        return doneChartData;
      case 2:
        return returnedChartData;
      default:
        return activeChartData;
    }
  };

  return (
    <>
      <div className="containerr">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='Искать везде' />
        <Title title='Искать везде' icon={<FindInPageRoundedIcon fontSize='meduim' />} />
        <div className="container-inner">

          <Stack direction='row' gap={1}>
            <form className="search-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="search-input-block">
                <Controller
                  name='phoneNumber'
                  defaultValue='+996'
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref, ...rest } }) => (
                    <TextField
                      label='Номер телефона'
                      size='small'
                      type="search"
                      value={value}
                      onChange={onChange}
                      onBlur={() => {
                        const trimmedValue = value.trim();
                        setValue('phoneNumber', trimmedValue);
                      }}
                      inputRef={ref}
                      {...rest}
                    />
                  )}
                />

                <Stack direction='row' gap={2}>
                  <Controller
                    name='cityFrom'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Откуда'
                        size='small'
                        select
                        fullWidth
                      >
                        <MenuItem value='Все'>Все</MenuItem>
                        {cities?.map((option) => (
                          <MenuItem key={option.name} value={parseInt(option.id)}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                  <Controller
                    name='cityTo'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label='Куда'
                        fullWidth
                        size='small'
                      >
                        <MenuItem value='Все'>Все</MenuItem>
                        {cities?.map((option) => (
                          <MenuItem key={option.name} value={parseInt(option.id)}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Stack>
                <Stack direction='row' gap={2}>
                  <Controller
                    name='dateFrom'
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <DatePicker
                        label="От"
                        value={field.value}
                        onChange={(date) => field.onChange(date)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  />
                  <Controller
                    name='dateTo'
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <DatePicker
                        label="До"
                        value={field.value}
                        onChange={(date) => field.onChange(date)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  />

                </Stack>
                <Controller
                  name='searchType'
                  control={control}
                  defaultValue='sender'
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="sender"
                        control={<Radio />}
                        label="Отправитель"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="receiver"
                        control={<Radio />}
                        label="Получатель"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="couriersForFilter"
                        control={<Radio />}
                        label="Курьер"
                        labelPlacement="top"
                      />
                    </RadioGroup>
                  )}
                />
                <Stack direction='row' gap={1}>
                  <Button type='submit' variant='black-contained' fullWidth>
                    Запрос
                  </Button>
                  <Tooltip title="Сбросить фильтры" placement='right' arrow>
                    <Button
                      variant='contained'
                      onClick={handleReset}
                      sx={{ width: '10%' }}
                      color='inherit'
                    >
                      <FilterAltOffRoundedIcon />
                    </Button>
                  </Tooltip>
                </Stack>
              </div>
            </form>
            <div className="dashboard-block" style={{ width: '70%' }}>
              <div className='dashboard-block-top-title'>
                <h2>Статистика заказов</h2>
                <Tooltip
                  title='Здесь отображается количество заказов по статусам: активные, доставленные и возвраты.'
                  placement='left'
                  arrow
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className='dashboard-chart-container'>
                {getChartData().length > 0 && (
                  <ResponsiveContainer width='100%' height={250}>
                    <LineChart
                      data={getChartData()}
                      barCategoryGap='10%'
                      stackOffset='none'
                    >
                      <CartesianGrid strokeDasharray="3 1" vertical={true} />
                      <XAxis
                        dataKey="date"
                        tick={{ fill: '#000' }}
                        axisLine={true}
                        tickLine={true}
                      />
                      <YAxis
                        tick={{ fill: '#000' }}
                        axisLine={true}
                        tickLine={true}
                      />
                      <ChartTooltip
                        labelFormatter={(value) => value}
                        contentStyle={{ borderRadius: '8px' }}
                      />
                      {tabIndex === 0 && (
                        <Line
                          type="monotone"
                          dataKey="Активные"
                          stroke={lavanda}
                          fill={lavanda}
                          fillOpacity={0.3}
                          strokeWidth={2}
                          activedot={{ r: 8 }}
                          dot={{ r: 3 }}
                          animationDuration={2000}
                          isAnimationActive
                        />
                      )}
                      {tabIndex === 1 && (
                        <Line
                          type="monotone"
                          dataKey="Доставленные"
                          stroke={green}
                          fill={green}
                          fillOpacity={0.3}
                          strokeWidth={2}
                          activedot={{ r: 8 }}
                          dot={{ r: 3 }}
                          animationDuration={2000}
                          isAnimationActive
                        />
                      )}
                      {tabIndex === 2 && (
                        <Line
                          type="monotone"
                          dataKey="Возвраты"
                          stroke={yellow}
                          fill={yellow}
                          fillOpacity={0.3}
                          strokeWidth={2}
                          animationDuration={2000}
                          activedot={{ r: 8 }}
                          dot={{ r: 3 }}
                          isAnimationActive
                        />
                      )}
                    </LineChart>
                  </ResponsiveContainer>
                )}

              </div>
            </div>
          </Stack>
          <div style={{ marginTop: '10px' }} />
          <div className="orders-card-wrapper">
            <div className="orders-card-header">
              <div className='orders-card-parent__btns'>
                <div>
                  <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    scrollButtons='auto'
                    variant='scrollable'
                  >
                    <Tab label={`Активные (${counts.active})`} />
                    <Tab label={`Завершенные (${counts.completed})`} />
                    <Tab label={`Возвраты (${counts.returned})`} />
                  </Tabs>
                </div>
              </div>
              {/* <SearchInput
                label='Поиск'
                placeholder='Введите'
                variant='outlined'
                value={search}
                onChange={handleSearchOrder}
              /> */}
            </div>
            {loading
              ? <Loader />
              : <OrderGrid
                orders={tabIndex === 0 ? orders : tabIndex === 1 ? ordersDone : ordersReturned || []}
                columns={columns}
                size={20}
                usePagination={false}
              />}
          </div>
        </div>
      </div>
    </>
  )
}

export default Search