import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Header } from '../../../../components/Header/Header';
import { Title } from '../../../../components/Title/Title';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../../../configs';
import HistoryList from '../../../../components/Lists/HistoryList';
import { Loader } from '../../../../components/Loader/Loader';
import noDataImage from '../../../../assets/images/no-data3.svg';
import NoData from '../../../../components/NoData/NoData';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import './History.scss';

const History = ({ pageTitle }) => {
  const { collectionId, id } = useParams();
  const [history, setHistory] = React.useState(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, collectionId, id, 'history'), orderBy('date', 'desc')),
      (snapshot) => {
        const histories = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setHistory(histories);
      }
    );
    return () => unsubscribe();
  }, [id]);

  return (
    <>
      <div className='container'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Детали заказа' initial='История заказа' />
        <Title
          title='История заказа'
          icon={<AccessTimeFilledRoundedIcon fontSize='24' />}
        />
        <div className='container-inner'>
          <div className='order-history-top'>
            <p>№</p>
            <h3>{id}</h3>
          </div>
          <div className='orders-history-wrapper'>
            {history
              ? <HistoryList items={history} />
              : <Loader />
            }
            {history?.length < 1 && <NoData img={noDataImage} text='История пуста' />}
          </div>
        </div>
      </div>
    </>
  );
};

export default History;