import React, { useEffect, useState } from 'react'
import { Header } from '../../../../components/Header/Header'
import PinDropRoundedIcon from '@mui/icons-material/PinDropRounded';
import { couriersRef } from './../../../../utils/collectionRefs';
import { onSnapshot } from 'firebase/firestore';
import { Title } from '../../../../components/Title/Title';
import { GoogleMap, InfoWindow, MarkerF, useLoadScript } from '@react-google-maps/api';
import { Rating } from '@mui/material';
import './scss/CourierDetail.scss'
import { Helmet } from 'react-helmet';

const CourierMap = ({pageTitle}) => {
  const [couriers, setCouriers] = useState(null)
  const [selectedCourier, setSelectedCourier] = useState(null);
  const [initialCenter, setInitialCenter] = useState({ lat: 42.882004, lng: 74.582748 });
  const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY })

  useEffect(() => {
    const fetchingCouriers = async () => {
      const unsubscribe = onSnapshot(couriersRef, snapshot => {
        const couriersData = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

        if (couriersData.length > 0) {
          setCouriers(couriersData);
        } else {
          console.log("No courier data found.");
        }
      });

      return () => {
        unsubscribe();
      }
    };

    fetchingCouriers();
  }, []);


  const handleVisibleInfo = (courierId) => {
    if (courierId === selectedCourier) {
      return;
    }
    setSelectedCourier(courierId)
  }

  return (
    <>
      <div className="container">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
        <Header previous='Статистика' initial={'Карта курьеров'} />
        <Title title='Карта курьеров' icon={<PinDropRoundedIcon fontSize='large' />} />
        <div className="container-inner">
          <div className='map-container'>
            <div className="map-inner">
              {isLoaded ? (
                <GoogleMap
                  center={initialCenter}
                  zoom={12}
                  onClick={() => setSelectedCourier(null)}
                  mapContainerStyle={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '12px'
                  }}
                >
                  {couriers?.map(({ id, lat, lon, name, surName, phone, type, raiting }) => (
                    <MarkerF
                      key={id}
                      position={{ lat: lat, lng: lon }}
                      onClick={() => handleVisibleInfo(id)}
                    >
                      {
                        selectedCourier === id ? (
                          <InfoWindow onCloseClick={() => setSelectedCourier(null)}>
                            <div>
                              <h3>Имя: {name} {surName}</h3>
                              <p>Телефон: {phone}</p>
                              <p>Тип курьера: {type}</p>
                              <p>
                                Рейтинг: <Rating defaultValue={parseFloat(raiting)} precision={0.5} size="small" readOnly />
                              </p>
                            </div>
                          </InfoWindow>
                        ) : null
                      }
                    </MarkerF>
                  ))}
                </GoogleMap>
              ) : null}
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default CourierMap