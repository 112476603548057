import React, { useCallback, useEffect, useState } from 'react'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import { citiesRef, villageRef } from '../../../../utils/collectionRefs';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import { Tab, Tabs } from '@mui/material';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import OrderGrid from '../../../../components/OrderGrid ';
import '../Couriers/scss/CourierDetail.scss';
import { Helmet } from 'react-helmet';
import { ExportCitiesToExcellButton } from '../../../../components/Buttons/ExportCitiesToExcell';
import { citiesColumns } from './helpers/CitiesColumns';
import { Loader } from '../../../../components/Loader/Loader';
import { fetchData } from './helpers/fetchData';
import { deleteDoc, doc } from 'firebase/firestore';
import { toast } from 'react-toastify';

const Cities = ({ pageTitle }) => {
  const [currentData, setCurrentData] = useState([])
  const [search, setSearch] = useState('')
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false)

  const loadInitialData = useCallback(async () => {
    setLoading(true);
    const data = await fetchData(citiesRef, 'name', 'asc');
    setCurrentData(data);
    setLoading(false);
  }, []);

  const handleTabChange = useCallback(async (event, newIndex) => {
    setTabIndex(newIndex);
    setLoading(true);
    const data = newIndex === 0
      ? await fetchData(citiesRef, 'name', 'asc')
      : await fetchData(villageRef, 'name', 'asc');
    setCurrentData(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  const handleDeleteDistrict = async (id) => {
    const LOADING_MESSAGE = `Удаление региона/района...`;
    let loadingToastId = toast.loading(LOADING_MESSAGE, { autoClose: false });

    try {
      if (!id) throw new Error('Invalid ID');

      const docRef = doc(villageRef, id)
      await deleteDoc(docRef)
      toast.dismiss(loadingToastId)
      toast.update(
        loadingToastId,
        {
          render: 'Регион/район успешно удален!',
          type: 'success',
          isLoading: false,
          autoClose: 3000
        });
    } catch (error) {
      console.error(error);
      toast.update(
        loadingToastId,
        {
          render: 'Ошибка при удалении региона/района',
          type: 'error',
          isLoading: false,
          autoClose: 5000
        });
    }
  }

  const handleSearchOrder = (e) => {
    setSearch(e.target.value.toLowerCase().trim());
  };

  const filteredData = currentData.filter((order) =>
    ['name', 'id'].some((field) => {
      const value = order[field];
      return value && value.toLowerCase().includes(search.toLowerCase());
    })
  );
  
  return (
    <>
      <div className='containerr'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='Список городов | Регионов' />
        <Title
          title={'Список городов | регионов'}
          icon={<PublicRoundedIcon fontSize='24' />}
        />
        <div className="container-inner">
          <div className="orders-sorting-header">
            <SearchInput
              label={`Поиск по ${tabIndex === 0 ? 'городу' : 'селе/региону'}`}
              placeholder='Введите'
              variant='outlined'
              onChange={handleSearchOrder}
            />
          </div>
          <div className='orders-card-wrapper'>
            <div className='orders-card-header'>
              <div className='orders-card-parent'>
                <div className='orders-card-parent__btns'>
                  <ExportCitiesToExcellButton ordersData={currentData} fileName='Список городов' />
                  <div>
                    <Tabs
                      value={tabIndex}
                      onChange={handleTabChange}
                      scrollButtons='auto'
                      variant='scrollable'
                    >
                      <Tab label={`Города`} />
                      <Tab label={`Регионы/Село`} />
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            {loading
              ? <Loader />
              : <OrderGrid
                orders={filteredData}
                columns={citiesColumns({ tabIndex, handleDeleteDistrict })}
                size={20}
              />}

          </div>
        </div>
      </div>
    </>
  )
}

export default Cities