

export const getTabsContent = (
  orders,
  completedOrders,
  returnedOrders,
  handleFilterChange,
  handleFilterChange2,
  handleFilterChange3,
  selectedDateFrom,
  selectedDateTo,
  selectedDateFrom2,
  selectedDateTo2,
  selectedDateFrom3,
  selectedDateTo3,
  handleDateChangeFrom,
  handleDateChangeTo,
  handleDateChangeFrom2,
  handleDateChangeTo2,
  handleDateChangeFrom3,
  handleDateChangeTo3,
  typeOfOrder,
  paymentPerson,
  orderStatus,
  returnedOrderStatus,
  lavanda,
  green,
  yellow
) => [
    {
      label: 'Активные',
      orders: orders,
      handleFilterChange: handleFilterChange,
      selectedDateFrom: selectedDateFrom,
      selectedDateTo: selectedDateTo,
      handleDateChangeFrom: handleDateChangeFrom,
      handleDateChangeTo: handleDateChangeTo,
      options: { typeOfOrder, paymentPerson, orderStatus },
      iconColor: lavanda,
    },
    {
      label: 'Завершенные',
      orders: completedOrders,
      handleFilterChange: handleFilterChange2,
      selectedDateFrom: selectedDateFrom2,
      selectedDateTo: selectedDateTo2,
      handleDateChangeFrom: handleDateChangeFrom2,
      handleDateChangeTo: handleDateChangeTo2,
      options: { typeOfOrder, paymentPerson },
      iconColor: green,
    },
    {
      label: 'Возвраты',
      orders: returnedOrders,
      handleFilterChange: handleFilterChange3,
      selectedDateFrom: selectedDateFrom3,
      selectedDateTo: selectedDateTo3,
      handleDateChangeFrom: handleDateChangeFrom3,
      handleDateChangeTo: handleDateChangeTo3,
      options: { typeOfOrder, paymentPerson, orderStatus: returnedOrderStatus },
      iconColor: yellow,
    },
  ];


export const getProgressBarData = (
  totalCompletedOrders,
  totalOrders,
  totalClients,
  totalActiveOrdersToday,
  totalTodayOrders,
  green,
  lavanda,
  lavandas,
  yellow,
  blue
) => {
  const maxCompletedOrders = Math.max(totalCompletedOrders, 40000);
  const maxOrders = Math.max(totalOrders, 2000);
  const maxClients = Math.max(totalClients, 2000);
  const maxTodayOrders = Math.max(totalTodayOrders, 400);
  return [
    {
      value: totalCompletedOrders,
      pathColor: green,
      textColor: green,
      trailColor: '#CCFBEF',
      subTitle: 'Доставленные',
      article: '',
      maxValue: maxCompletedOrders,
    },
    {
      value: totalOrders,
      pathColor: lavanda,
      textColor: lavanda,
      trailColor: lavandas,
      subTitle: 'Активные',
      article: '',
      maxValue: maxOrders,
    },
    {
      value: totalClients,
      pathColor: yellow,
      textColor: yellow,
      trailColor: '#FFF3C6',
      subTitle: 'Возвраты',
      article: '',
      maxValue: maxClients,
    },
    {
      value: totalActiveOrdersToday,
      pathColor: lavanda,
      textColor: lavanda,
      trailColor: lavandas,
      subTitle: 'Заказы за сегодня',
      article: '',
      maxValue: 700,
    },
    {
      value: totalTodayOrders,
      pathColor: '#f54db7',
      textColor: '#f54db7',
      trailColor: '#f54db771',
      subTitle: 'Все заказы',
      article: '',
      maxValue: maxTodayOrders,
    },
  ]
};


export const balanceCards = (orders) => {
  return [
    {
      title: 'Количество заказов',
      value: orders?.count,
    },
    {
      title: 'Cумма заказов',
      value: orders?.cost,
    },
    {
      title: 'Cумма выкупов',
      value: orders?.redemption,
    },
  ]
};

