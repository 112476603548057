import { db } from '../configs';
import { collection } from 'firebase/firestore';

const userName = localStorage.getItem('userName');
const roleType = localStorage.getItem('roleType');
// COLLECTIONS
export const adminRef = collection(db, 'admin');
export const managerRef = collection(db, 'manager');
export const accountantRef = collection(db, 'accountant');
export const orderRef = collection(db, 'orders');
export const returnedOrderRef = collection(db, 'orders_returned');
export const completedOrdersRef = collection(db, 'orders_done');
export const canceledOrdersRef = collection(db, 'orders_cancelled');
export const archivedOrdersRef = collection(db, 'orders_archived');
export const testOrderRef = collection(db, 'orders_test');
export const transactionsRef = collection(db, 'transactions');
export const tariffRef = collection(db, 'tariffs');
export const citiesRef = collection(db, 'city');
export const cityOrdersRef = collection(db, 'city_orders');
export const villageRef = collection(db, 'village');
export const couriersRef = collection(db, 'couriers');
export const clientsRef = collection(db, 'clients');
export const deleteRequestRef = collection(db, 'deleteRequest');
export const faqRef = collection(db, 'faq');
export const storesRef = collection(db, 'stores');
export const storeCategorysRef = collection(db, 'storecats');
export const userCollectionRef = collection(db, `${roleType}`, `${userName}`, 'history');