// utils/handleChangeStatus.js

import { toast } from 'react-toastify';
import { doc, updateDoc, collection, getDoc } from 'firebase/firestore';
import { addToHistory } from './../../../../../utils/addToHistory';
import { db } from '../../../../../configs';

const USERTYPE = localStorage.getItem('userType');
const USERNAME = localStorage.getItem('userName');
const ROLETYPE = localStorage.getItem('roleType');

const updateOrderStatus = async (orderRef, newStatus) => {
  const orderSnapshot = await getDoc(orderRef);
  const orderData = orderSnapshot.data();
  if (
    newStatus === 'status_at_sorting_center2' ||
    newStatus === 'status_on_way_cc'
  ) {
    const addressToCity = orderData.addressTo?.city;
    await updateDoc(orderRef, {
      status: newStatus,
      statusFilter: [newStatus],
      cityFilter: addressToCity,
    });
  } else {
    const addressFromCity = orderData.addressFrom?.city;
    await updateDoc(orderRef, {
      status: newStatus,
      statusFilter: [newStatus],
      cityFilter: addressFromCity,
    });
  }

  return 'Update successful';
};

export const handleChangeStatus = async ({
  dataRef,
  orders,
  id,
  newStatus,
  statusLabels,
  alternativeStatusLabels,
}) => {
  const orderIndex = orders.findIndex((order) => order.id === id);
  if (orderIndex !== -1) {
    const oldStatus = orders[orderIndex].status;
    let loadingToastId;
    try {
      const ref = doc(dataRef, id);
      const historyRef = collection(ref, 'history');
      const userHistoryRef = collection(db, ROLETYPE, USERNAME, 'history');
      const description = `${USERTYPE} ${USERNAME} изменил статус заказа из "${statusLabels[oldStatus]}" ➜ "${newStatus}"`;
      loadingToastId = toast.loading('Изменение статуса...', {
        autoClose: false,
      });

      await Promise.all([
        updateOrderStatus(ref, alternativeStatusLabels[newStatus]),
        addToHistory(historyRef, description),
        addToHistory(userHistoryRef, description),
      ]);

      toast.dismiss(loadingToastId);
      toast.success('Статус заказа успешно изменен!');
    } catch (error) {
      console.error(error.message);
      toast.dismiss(loadingToastId);
      toast.error('Произошла ошибка при изменении статуса');
    }
  } else {
    toast.error('Заказ не найден или не имеет допустимого статуса');
  }
};

