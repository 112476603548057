import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { LinearProgress } from '@mui/material';
import './scss/DataGrid.scss';
import { CustomNoResultsOverlay, CustomNoRowsOverlay } from './CustomOverlays';
import ScrollToTop from './ScrollToTop';

const OrderGrid = ({
  orders,
  columns,
  size,
  handleChangeStatus,
  loading,
  getRowClassName,
  page,
  onPageChange,
  onPageSizeChange,
  totalRowCount,
  usePagination = false,
}) => {
  const isScrollToTopVisible = size > 50;
  const getRegularRowClassName = (params) => {
    const { indexRelativeToCurrentPage } = params;
    return indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow';
  };


  const handlePageChange = (params) => {
    if (usePagination && onPageChange) {
      onPageChange(params);
    }
  };

  return (
    <>
      <div className='customDataGrid-table' style={{ width: '100%' }}>
        <DataGrid
          rows={orders || []}
          columns={columns}
          editMode='cell'
          pageSize={size}
          pagination={usePagination}
          page={page}
          paginationMode={usePagination ? 'server' : 'client'}
          rowCount={usePagination ? totalRowCount : orders?.length}
          loading={loading}
          onPageChange={handlePageChange}
          onPageSizeChange={(params) => onPageSizeChange(params)}
          getRowClassName={!getRowClassName ? getRegularRowClassName : getRowClassName}
          autoHeight
          disableColumnMenu
          disableColumnFilter
          rowsPerPageOptions={usePagination ? [5, 10, 20, 50, 100] : []}
          onEditCellPropsChange={(params, event) => {
            const newStatus = event.target.value;
            handleChangeStatus(params.id, newStatus);
          }}
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: orders?.length === 0 ? CustomNoResultsOverlay : CustomNoRowsOverlay
          }}
          componentsProps={{
            pagination: {
              labelRowsPerPage: "Кол-во списков на странице"
            }

          }}
        />
        <ScrollToTop isVisible={isScrollToTopVisible} />
      </div>
    </>
  );
};

export default OrderGrid;
