import React from 'react'
import OrderSort from '../../../../../components/OrderSort';
import { paymentPerson, typeOfOrder } from '../../../../../utils';
import BalanceCard from '../../../../../components/BalanceCard/BalanceCard';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';

import { Stack } from '@mui/material';
import { balanceCards } from '../constants';

const TabContent = ({
  orders,
  handleFilterChange,
  selectedDateFrom,
  selectedDateTo,
  handleDateChangeFrom,
  handleDateChangeTo,
  options,
  iconColor,
}) => {
  const cards = balanceCards(orders);
  
  return (

    <>
      <div className='orders-sorting-header clear-btn'>
        <Stack sx={{ width: '100%', flexWrap: 'wrap' }} direction='row' gap={2}>
          <OrderSort
            sortKey='По типу'
            defaultValue=''
            options={typeOfOrder}
            onChange={(e) => handleFilterChange('packageType', e.target.value)}
          />
          <OrderSort
            sortKey='Кто оплачивает'
            defaultValue=''
            options={paymentPerson}
            onChange={(e) => handleFilterChange('whoPays', e.target.value)}
          />
          {options?.orderStatus && (
            <OrderSort
              sortKey='По статусу'
              defaultValue=''
              options={options.orderStatus}
              onChange={(e) => handleFilterChange('status', e.target.value)}
            />
          )}
          <div className='order-sort order-sort-date'>
            <label htmlFor='orderDate' className='orders-sort-date-label'>
              От
              <input
                className='order-sort-dateInput'
                type='date'
                name='orderDate'
                id='orderDate'
                value={selectedDateFrom || ''}
                onChange={handleDateChangeFrom}
              />
            </label>
            <span></span>
            <label htmlFor='orderDate2' className='orders-sort-date-label'>
              До
              <input
                className='order-sort-dateInput'
                type='date'
                name='orderDate2'
                id='orderDate2'
                value={selectedDateTo || ''}
                onChange={handleDateChangeTo}
              />
            </label>
          </div>
        </Stack>
      </div>
      {
        cards.map((item, index) => (
          <BalanceCard
            key={index}
            title={item.title}
            value={item.value}
            icon={MonetizationOnRoundedIcon}
            iconColor={iconColor}
            borderColor={iconColor}
          />
        ))
      }
    </>
  );
}


export default TabContent