export const transactionStatusLabels = {
  fuel: 'Бензин',
  parking: 'Парковка',
  taxi: 'Такси',
  courier_cost: 'Доля курьера',
  delivery: 'Услуга доставки',
  vykup_minus: 'Оплата за выкуп',
  vykup_plus: 'Получение суммы выкупа',
  admin_plus: 'Админ плюс',
  admin_minus: 'Админ минус',
  return_vykup: 'Получение выкупа (Возврат)',
  return_delivery: 'Получение за услуги доставки (Возврат)',
  cancel_order: 'Отмена заказа',
  detail_change: 'Изменение в деталях',
  other: 'Другое',
};

export const typeOfTransactionLables = [
  { value: 'fuel', name: 'Бензин' },
  { value: 'parking', name: 'Парковка' },
  { value: 'taxi', name: 'Такси' },
  { value: 'courier_cost', name: 'Доля курьера' },
  { value: 'delivery', name: 'Услуга доставки' },
  { value: 'vykup_minus', name: 'Оплата за выкуп' },
  { value: 'vykup_plus', name: 'Получение суммы выкупа' },
  { value: 'admin_plus', name: 'Админ плюс' },
  { value: 'admin_minus', name: 'Админ минус' },
  { value: 'return_vykup', name: 'Получение выкупа (Возврат)' },
  { value: 'return_delivery', name: 'Получение за услуги доставки (Возврат)' },
  { value: 'cancel_order', name: 'Отмена заказа' },
  { value: 'detail_change', name: 'Изменение в деталях' },
  { value: 'other', name: 'Другое' },
];