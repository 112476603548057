import { IconButton, Tooltip } from "@mui/material";
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import { Link } from "react-router-dom";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

export const citiesColumns = ({ tabIndex, handleDeleteDistrict }) => {
  return [
    {
      flex: 0.2,
      field: 'id',
      sortable: true,
      renderHeader: () => <strong>ID</strong>,
    },
    {
      flex: 1,
      field: 'name',
      renderHeader: () => <strong>Название</strong>,
      disableColumnMenu: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
    },
    ...(tabIndex === 1 ? [{
      flex: 0.2,
      field: 'district',
      renderHeader: () => <strong>ID региона/село</strong>,
      disableColumnMenu: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell'
    }] : []),
    {
      field: 'actions',
      sortable: false,
      flex: 0.2,
      menubar: false,
      renderCell: (params) => (
        <div>
          <Tooltip title='Редактировать' arrow>
            <Link
              to={tabIndex === 0 ? `/city/update/${params.id}` : `/village/update/${params.id}`}
              target='_blank'
            >
              <IconButton aria-label='edit'>
                <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
              </IconButton>
            </Link>
          </Tooltip>
          {tabIndex === 1 ? (
            <Tooltip title='Удалить' arrow>
              <IconButton aria-label='delete' onClick={() => handleDeleteDistrict(params?.row.id)}>
                <DeleteRoundedIcon style={{ color: '#000b1f' }} />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      ),
      renderHeader: () => <strong>Действия</strong>,
    }
  ]
};