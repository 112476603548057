export const dateFormatter = (timestampInSeconds) => {
  if (!timestampInSeconds) {
    return 'Нет даты';
  }

  const date = new Date(timestampInSeconds * 1000);

  const formatDateNumber = (number) => {
    return number < 10 ? `0${number}` : number;
  }

  const year = date.getFullYear();
  const month = formatDateNumber(date.getMonth() + 1);
  const day = formatDateNumber(date.getDate());
  const hours = formatDateNumber(date.getHours());
  const minutes = formatDateNumber(date.getMinutes());

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}
export const dateFormatter2 = (dateString) => {
  const date = new Date(dateString);

  const formatDateNumber = (number) => {
    return number < 10 ? `0${number}` : number;
  }

  const year = date.getFullYear();
  const month = formatDateNumber(date.getMonth() + 1);
  const day = formatDateNumber(date.getDate());
  
  return `${day}/${month}/${year}`;
}
export const dateFormatter3 = (dateString) => {
  const date = new Date(dateString);

  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();
  // Возвращаем строку с месяцем и днем
  return `${month} ${day}`;
}

export const getDayAgo = (daysAgo) => {
  const date = new Date();
  date.setDate(date.getDate() - daysAgo);
  date.setHours(0, 0, 0, 0); 
  return date;
};

export const hourFormatter = (timestampInSeconds) => {
  if (!timestampInSeconds) {
    return 'Нет даты';
  }

  const date = new Date(timestampInSeconds * 1000);

  const formatNumber = (number) => {
    return number < 10 ? `0${number}` : number.toString();
  };

  const hours = formatNumber(date.getHours());
  const minutes = formatNumber(date.getMinutes());

  return `${hours}:${minutes}`;
};