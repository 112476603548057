import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  addDoc,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import {
  payment,
  paymentPerson,
  paymentStatus,
  typeOfOrder,
} from '../../../../utils/index';
import {
  citiesRef,
  orderRef,
  tariffRef,
  villageRef,
} from '../../../../utils/collectionRefs';
import { db } from '../../../../configs';
import { Title } from '../../../../components/Title/Title';
import { FormValidation } from '../../../../components/Form/FormValidation/exports';
import { Loader } from '../../../../components/Loader/Loader';
import { Header } from '../../../../components/Header/Header';
import {
  Button,
  TextField,
  MenuItem,
  Box,
  Backdrop,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { toast } from 'react-toastify';
import './scss/AddOrder.scss';
import { Helmet } from 'react-helmet';

const AddOrder = ({ pageTitle }) => {
  const date = new Date();
  const [open, setOpen] = useState(false);
  const [city, setCity] = useState(null);
  const [tariff, setTariff] = useState(null);
  // const [clients, setClients] = useState([]);
  // const [clients2, setClients2] = useState([]);
  const [district, setDistrict] = useState(null);
  const [district2, setDistrict2] = useState(null);
  // const [isMenuOpen, setMenuOpen] = React.useState(false);
  // const [isMenuOpen2, setMenuOpen2] = React.useState(false);
  // const [selectedClient, setSelectedClient] = useState(null);
  // const [selectedClient2, setSelectedClient2] = useState(null);
  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    const settingTariff = onSnapshot(tariffRef, (snapshot) => {
      setTariff(snapshot.docs.map((doc) => ({ ...doc.data() })));
    });
    const settingCity = onSnapshot(citiesRef, (snapshot) => {
      const cityData = snapshot.docs.map((doc) => ({ ...doc.data() }));
      const sortedCityData = cityData.sort((a, b) => a.id - b.id);
      setCity(sortedCityData);
    });
    return () => {
      settingTariff();
      settingCity();
    };
  }, []);

  const fetchDistricts = async (selectedCity, setDistrictFunction) => {
    const id = selectedCity.id;
    const filteredDist = query(villageRef, where('district', '==', id));
    const districtDocs = await getDocs(filteredDist);
    setDistrictFunction(districtDocs?.docs?.map((doc) => ({ ...doc?.data() })));
  };

  // const searchClientInFirebase = async (phoneSubstring) => {
  //   const q = query(
  //     clientsRef,
  //     where('forSearch', 'array-contains', phoneSubstring)
  //   );
  //   const querySnapshot = await getDocs(q);
  //   const matchingClients = querySnapshot.docs.map((doc) => doc.data());
  //   return matchingClients;
  // };

  // const fetchData = async (selectedCity, setDistrictFunction, phoneSubstring) => {
  //   const id = selectedCity.id;
  //   const filteredDist = query(villageRef, where('district', '==', id));
  //   const districtDocs = await getDocs(filteredDist);
  //   setDistrictFunction(districtDocs?.docs?.map((doc) => ({ ...doc?.data() })));

  //   const q = query(clientsRef, where('forSearch', 'array-contains', phoneSubstring));
  //   const querySnapshot = await getDocs(q);
  //   const matchingClients = querySnapshot.docs.map((doc) => doc.data());
  //   return matchingClients;
  // };

  // const handlePhoneChange = debounce(async (phone) => {
  //   const matchingClients = await searchClientInFirebase(phone);
  //   setClients(matchingClients);
  //   setMenuOpen(matchingClients.length > 0);
  // }, 500);

  // const handlePhoneChange2 = debounce(async (phone) => {
  //   const matchingClients = await searchClientInFirebase(phone);
  //   setClients2(matchingClients);
  //   setMenuOpen2(matchingClients.length > 0);
  // }, 500);

  // const handleMenuItemClick = useCallback(
  //   (event, data) => {
  //     setSelectedClient(data);
  //     setMenuOpen(false);
  //     setValue('fromName', data.fullName);
  //     setValue('fromPhone', data.phone);
  //   },
  //   [setSelectedClient, setMenuOpen, setValue]
  // );

  // const handleMenuItemClick2 = useCallback(
  //   (event, data) => {
  //     setSelectedClient2(data);
  //     setMenuOpen2(false);
  //     setValue('toName', data.fullName);
  //     setValue('toPhone', data.phone);
  //   },
  //   [setSelectedClient2, setMenuOpen2, setValue]
  // );

  // const renderMenuItems = useCallback(() => {
  //   return clients?.map((item) => (
  //     <li
  //       key={item?.phone}
  //       onClick={(event) => handleMenuItemClick(event, item)}
  //     >
  //       <p>
  //         {item?.fullName} ({item?.phone})
  //       </p>
  //     </li>
  //   ));
  // }, [clients, handleMenuItemClick]);

  // const renderMenuItems2 = useCallback(() => {
  //   return clients2?.map((item) => (
  //     <li
  //       key={item?.phone}
  //       onClick={(event) => handleMenuItemClick2(event, item)}
  //     >
  //       <p>
  //         {item?.fullName} ({item?.phone})
  //       </p>
  //     </li>
  //   ));
  // }, [clients2, handleMenuItemClick2]);

  const handlePostOrder = async (order) => {
    const userCollectionRef = collection(
      db,
      `${roleType}`,
      `${userName}`,
      'history'
    );
    // const senderClients = await searchClientInFirebase(order.fromPhone);
    // const receiverClients = await searchClientInFirebase(order.toPhone);
    // const senderClientExists = senderClients.some(
    //   (client) => client.phone === order.fromPhone
    // );
    // const receiverClientExists = receiverClients.some(
    //   (client) => client.phone === order.toPhone
    // );

    try {
      setOpen(!open);
      // if (!senderClientExists) {
      //   const clientData = {
      //     address: {
      //       address: order.fromAdress,
      //       city: order.fromCity?.id ?? null,
      //       cityName: order.fromCity?.name ?? null,
      //       district: order?.fromDistrict?.id ?? null,
      //       districtName: order?.fromDistrict?.name ?? null,
      //       lat: 0,
      //       lon: 0,
      //     },
      //     avatar: '',
      //     blackList: false,
      //     comments: '',
      //     forSearch: generateForSearch(order.fromPhone),
      //     fullName: order.fromName,
      //     orders: 0,
      //     phone: order.fromPhone,
      //     uid: order.fromPhone,
      //   };
      //   await setDoc(doc(db, 'clients', `${clientData?.phone}`), clientData);
      //   toast.success('Новый клиент успешно добавлен!', {
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //   });
      // }
      // if (!receiverClientExists) {
      //   const clientData = {
      //     address: {
      //       address: order.toAdress,
      //       city: order.toCity?.id ?? null,
      //       cityName: order.toCity?.name ?? null,
      //       district: order?.toDistrict?.id ?? null,
      //       districtName: order?.toDistrict?.name ?? null,
      //       lat: 0,
      //       lon: 0,
      //     },
      //     avatar: '',
      //     blackList: false,
      //     comments: '',
      //     forSearch: generateForSearch(order.toPhone),
      //     fullName: order.toName,
      //     orders: 0,
      //     phone: order.toPhone,
      //     uid: order.toPhone,
      //   };
      //   await setDoc(doc(db, 'clients', `${clientData?.phone}`), clientData);
      //   toast.success('Новый клиент успешно добавлен!', {
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //   });
      // }

      const sendData = {
        addressFrom: {
          address: order.fromAdress,
          city: order.fromCity?.id ?? null,
          cityName: order.fromCity?.name ?? null,
          district: order?.fromDistrict?.id ?? null,
          districtName: order?.fromDistrict?.name ?? null,
          lat: 42.876254,
          lon: 74.604228,
        },
        addressTo: {
          address: order.toAdress,
          city: order.toCity?.id ?? null,
          cityName: order.toCity?.name ?? null,
          district: order?.toDistrict?.id ?? null,
          districtName: order?.toDistrict?.name ?? null,
          lat: 42.876254,
          lon: 74.604228,
        },
        tariff: {
          cost: order.tariff.cost,
          name: order.tariff.name,
          uid: String(order.tariff.order),
        },
        tariffId: String(order.tariff.order),
        creator: userName,
        cancellationReason: '',
        comments: order.commits,
        cost: !order.cost ? order.tariff.cost : Number(order.cost),
        cityFilter: order.fromCity?.id ?? null,
        cityFrom: order.fromCity?.id ?? null,
        cityTo: order.toCity?.id ?? null,
        courierOne: '',
        courierTwo: '',
        dateCreated: date,
        packageType: order.orderType,
        paymentMethod: order.paymentMethod,
        paymentStatus: order.paymentStatus === 'false' ? false : true,
        pid: `AE-${order.toCity?.id ?? null}-${date.getTime()}`,
        receiver: order.toPhone,
        receiverName: order.toName,
        receiverPhone: order.toPhone,
        redemption: Number(order.redemption),
        sender: order.fromPhone,
        senderName: order.fromName,
        senderPhone: order.fromPhone,
        status: 'status_new',
        statusFilter: ['status_new'],
        whoPays: Number(order.paymentPerson),
      };
     
      await addDoc(orderRef, sendData);
      await addDoc(userCollectionRef, {
        date: new Date(),
        description: `${userType} ${userName} добавил заказ`
      })
      setOpen(false);
      navigate('/orders');

      toast.success('Заказ успешно добавлен', {
        autoClose: 2000,
        hideProgressBar: false,
      });

      reset();
    } catch (e) {
      setOpen(false);
      console.log(e)
      toast.error('Произошла ошибка при добавлении заказа', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  return (
    <>
      <div className='container'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous={'Статистика'} initial={'Добавление заказа'} />
        <Title
          title={'Добавление заказа'}
          icon={<AddCircleIcon fontSize='meduim' />}
        />
        <div className='container-inner'>
          {!city ? (
            <Loader />
          ) : (
            <form className='order-form'>
              <div className='order-form-flex'>
                {/* ОТПРАВИТЕЛЬ */}
                <div className='order-block sender-block'>
                  <div className='order-block-head'>
                    <h3>Отправитель</h3>
                  </div>
                  <div className='order-input-block'>
                    <Controller
                      name='fromPhone'
                      control={control}
                      defaultValue=''
                      rules={{ required: 'Объязательное поле' }}
                      render={({ field }) => (
                        <TextField
                          label='Отправитель'
                          size='small'
                          error={!!errors?.fromPhone}
                          helperText={errors?.fromPhone?.message}
                          // inputRef={inputRef}
                          {...field}
                          // onChange={(e) => {
                          //   handlePhoneChange(e.target.value);
                          //   if (e.target.value === '') {
                          //     setValue('fromPhone', ''); // Если значение пустое, сбросить его
                          //     setValue('fromName', ''); // Если значение пустое, сбросить его
                          //   }
                          //   setValue('fromPhone', e.target.value);
                          // }}
                        />
                      )}
                    />
                    {/* <div
                      className={
                        !isMenuOpen
                          ? 'testing-client-list'
                          : 'testing-client-list open'
                      }
                    >
                      <ul>{renderMenuItems()}</ul>
                    </div> */}
                    <TextField
                      id='outlined-basic'
                      label={'Имя отправителя'}
                      variant='outlined'
                      size='small'
                      helperText={errors?.fromName?.message}
                      error={errors?.fromName && true}
                      {...register('fromName', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />

                    <Controller
                      name='fromCity'
                      control={control}
                      defaultValue=''
                      rules={{ required: 'Объязательное поле' }}
                      render={({ field }) => (
                        <Autocomplete
                          id='from-city'
                          disablePortal
                          options={city || []}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, val) => {
                            setValue('fromDistrict', '');
                            field.onChange(val);
                            fetchDistricts(val, setDistrict);
                          }}
                          value={field.value || null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={`random-${Math.random()
                                .toString(36)
                                .substring(7)}`}
                              label='Город/регион'
                              variant='outlined'
                              size='small'
                              error={!!errors?.fromCity}
                              helperText={errors?.fromCity?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name='fromDistrict'
                      control={control}
                      defaultValue=''
                      rules={{
                        required: 'Объязательное поле',
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          id='from-district'
                          disablePortal
                          options={district || []}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, val) => field.onChange(val)}
                          value={field.value || null}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                name={`random-${Math.random()
                                  .toString(36)
                                  .substring(7)}`}
                                label='Район/село'
                                variant='outlined'
                                size='small'
                                error={!!errors?.fromDistrict}
                                helperText={errors?.fromDistrict?.message}
                                className='w-full'
                              />
                            );
                          }}
                        />
                      )}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Адрес отправителя'
                      variant='outlined'
                      size='small'
                      error={errors?.fromAdress ? true : false}
                      helperText={errors ? errors?.fromAdress?.message : ''}
                      {...register('fromAdress', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                  </div>
                </div>
                {/* ----------------- */}
                {/* ПОЛУЧАТЕЛЬ */}
                <div className='order-block sender-block'>
                  <div className='order-block-head'>
                    <h3>Получатель</h3>
                  </div>
                  <div className='order-input-block'>
                    <Controller
                      name='toPhone'
                      control={control}
                      defaultValue=''
                      rules={{ required: 'Объязательное поле' }}
                      render={({ field }) => (
                        <TextField
                          label='Получатель'
                          size='small'
                          error={!!errors?.toPhone}
                          helperText={errors?.toPhone?.message}
                          // inputRef={inputRef2}
                          {...field}
                        // onChange={(e) => {
                        //   handlePhoneChange2(e.target.value);
                        //   if (e.target.value === '') {
                        //     setValue('toPhone', ''); // Если значение пустое, сбросить его
                        //     setValue('toName', ''); // Если значение пустое, сбросить его
                        //   }
                        //   setValue('toPhone', e.target.value);
                        // }}
                        />
                      )}
                    />
                    {/* <div
                      className={
                        !isMenuOpen2
                          ? 'testing-client-list'
                          : 'testing-client-list open'
                      }
                    >
                      <ul>{renderMenuItems2()}</ul>
                    </div> */}
                    <TextField
                      id='outlined-basic'
                      label={'Имя получателя'}
                      variant='outlined'
                      size='small'
                      helperText={errors?.toName?.message}
                      error={errors?.toName && true}
                      {...register('toName', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                    <Controller
                      name='toCity'
                      control={control}
                      defaultValue=''
                      rules={{ required: 'Объязательное поле' }}
                      render={({ field }) => (
                        <Autocomplete
                          id='to-city'
                          disablePortal
                          options={city || []}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, val) => {
                            setValue('toDistrict', '');
                            field.onChange(val);
                            fetchDistricts(val, setDistrict2);
                          }}
                          value={field?.value || null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={`random-${Math.random()
                                .toString(36)
                                .substring(7)}`}
                              label='Город/регион'
                              variant='outlined'
                              size='small'
                              error={!!errors?.toCity}
                              helperText={errors?.toCity?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name='toDistrict'
                      control={control}
                      defaultValue=''
                      rules={{
                        required: 'Объязательное поле',
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          id='to-district'
                          disablePortal
                          options={district2 || []}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, val) => field.onChange(val)}
                          value={field?.value || null}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                name={`random-${Math.random()
                                  .toString(36)
                                  .substring(7)}`}
                                label='Район/село'
                                variant='outlined'
                                size='small'
                                error={!!errors?.toDistrict}
                                helperText={errors?.toDistrict?.message}
                                className='w-full'
                              />
                            );
                          }}
                        />
                      )}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Адрес получателя'
                      variant='outlined'
                      size='small'
                      error={errors?.toAdress ? true : false}
                      helperText={errors ? errors?.toAdress?.message : ''}
                      {...register('toAdress', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                  </div>
                </div>
                {/* ------------- */}
                {/* ДЕТАЛИ ЗАКАЗА */}
                <div className='order-block'>
                  <div className='order-block-head'>
                    <h3>Детали заказа</h3>
                  </div>
                  <div className='order-input-block'>
                    <Box sx={{ display: 'flex', gap: '4px' }}>
                      <TextField
                        sx={{ width: '90%' }}
                        id='filled-select-currency'
                        select
                        label='Тип посылки'
                        defaultValue={typeOfOrder[0].value}
                        variant='outlined'
                        size='small'
                        error={errors?.orderType && true}
                        {...register('orderType', {
                          required: FormValidation.RequiredInput.required,
                        })}
                      >
                        {typeOfOrder.map((type) => (
                          <MenuItem key={type.id} value={type.value}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </TextField>

                      <Controller
                        name='tariff'
                        control={control}
                        defaultValue={tariff?.[0]}
                        rules={{ required: 'Обязательное поле!' }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            disablePortal
                            options={tariff || []}
                            getOptionLabel={(option) =>
                              `${option?.name}(${option?.cost}с)` || ''
                            }
                            style={{ width: '90%' }}
                            onChange={(_, val) => {
                              field.onChange(val);
                              setValue('cost', val?.cost);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Тариф'
                                variant='outlined'
                                className='add-courier-input'
                                size='small'
                                style={{ width: '100%' }}
                                error={!!errors.tariff}
                                helperText={errors?.tariff?.message}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>

                    <TextField
                      type='text'
                      size='small'
                      label=' Укажите  цену'
                      defaultValue={
                        !getValues('cost') ? tariff[0]?.cost : getValues('cost')
                      }
                      {...register('cost')}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Выкуп (0 если без выкупа)'
                      variant='outlined'
                      size='small'
                      defaultValue={0}
                      type='number'
                      {...register('redemption', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                    <TextField
                      id='filled-select-currency'
                      select
                      label='Кто оплачивает'
                      defaultValue={paymentPerson[0].value}
                      variant='outlined'
                      size='small'
                      {...register('paymentPerson', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    >
                      {paymentPerson.map((person) => (
                        <MenuItem key={person.id} value={person.value}>
                          {person.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id='filled-select-currency'
                      select
                      label='Метод оплаты'
                      defaultValue={payment[0].value}
                      variant='outlined'
                      size='small'
                      {...register('paymentMethod', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    >
                      {payment.map((type) => (
                        <MenuItem key={type.id} value={type.value}>
                          {type.title}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id='filled-select-currency'
                      select
                      label='Статус оплаты'
                      defaultValue={paymentStatus[0].value}
                      variant='outlined'
                      size='small'
                      {...register('paymentStatus', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    >
                      {paymentStatus.map((type) => (
                        <MenuItem key={type.id} value={type.value}>
                          {type.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                {/* ДОПОЛНИТЕЛЬНО */}
                <div className='order-block'>
                  <div className='order-block-head'>
                    <h3>Дополнительно</h3>
                  </div>
                  <div className='order-input-block'>
                    <TextField
                      id='outlined-multiline-static'
                      label='Коментарии'
                      multiline
                      rows={4}
                      {...register('commits')}
                    />
                  </div>
                </div>
              </div>
              <Box sx={{ display: 'flex', gap: '4px' }}>
                <Button
                  onClick={handleSubmit((data) => handlePostOrder(data))}
                  size='large'
                  variant='contained'
                  style={{ background: '#000b1f' }}
                >
                  Оформить заказ
                </Button>
              </Box>
            </form>
          )}
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
    </>
  );
};
export default AddOrder;
